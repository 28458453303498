<template>
  <div class="updatePassword">
    <!--        <div style=" width: 92%;height: 1.2rem;"></div>-->
    <rxNavBar title="房间配置"></rxNavBar>
    <div class="newtime"><span class="diamod"></span>{{this.name}}</div>
   <div class="appointmentDetail" v-for="(item, index) in arrList" :key="index">
      <div class="roomMesage">
        <van-dialog v-model="showee" title="实物图" show-cancel-button>
          <img :src="path"/>
        </van-dialog>
        <div class="address">{{ item.itemName }}</div>
        <div class="areaDiv">
           
          <div class="right">
            <span class="rightSpan" @click="setHouseItemPhone(item)">查看实物图></span>
          </div>
        </div>
      </div>
    </div>
    <div class="newtime" ><span class="diamod"></span> {{this.houseName}}</div>
    <div class="appointmentDetail" v-for="(item, index) in houseList" :key="index">
      <div class="roomMesage">
        <van-dialog v-model="showee" title="实物图" show-cancel-button>
          <img :src="path"/>
        </van-dialog>
        <div class="address">{{item.itemName}}</div>
        <div class="areaDiv">

          <div class="right">
            <span class="rightSpan" @click="setHouseItemPhone(item)">查看实物图></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup,
  Field,
  Dialog,
} from "vant";
import {
  checkAndroid,
  checkIOS,
  globaluserId,
  money,
  openInWebview,
  responseUtil,
  
} from "../../libs/rongxunUtil";
import {
  bannerLinkUser,
  roomDetail,
  addUserAppointmentNew,
  updataHousePrice,
  browseSetHouseItem,
  setHouseItemPhone,
} from "../../getData/getData";

//调用android关闭页面方法*******begin*********
function backToAndroid() {
  window.himi.closeWindow();
}
// import {} from "../../getData/getData";
// import {responseUtil} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
export default {
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    rxNavBar,
  },
  data() {
    return {
      path:'',
      houseName:'',
      name:'',
      floorPrice: "", // 底价
      offerPrice: "", // 报价
      roomId: "",
      setHouse_id: "",
      roomHouse_id: "",
      roomhouse:"",
      month: "",
      date: new Date(),
      dateTime: true,
      show: false,
      showaa: false,
      dateformat: "",
      timeImg: require("../../assets/images/timepick.png"),
      headImg: require("../../assets/images/head-quotation-mark.png"),

      appointmentData: {
        photo: require("../../assets/images/1.png"),
        address: "天通苑南 天通苑西区 朝南主…",
        district: "沙河口",
        area: "123m²",
        floor: "6层",
        money: "¥ 809",
      },
      showee: false, 
	  arrList:[],
    houseList:[]
    };
  },
  mounted() {
    var that = this; 
    this.roomId = this.$route.query.room_id;
    this.setHouse_id = this.$route.query.sethouse_id
    // that.initData()
    // console.log(that.initData);
    this.browseSetHouseItem(); 
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存 
    if (from.name == null) {
      localStorage.removeItem("currentLabel");
      localStorage.removeItem("type");
      localStorage.removeItem("inputValue");
      localStorage.removeItem("currentLabelPublic");
    }
    next();
  },
  methods: {
    //我的
    browseSetHouseItem() {
      let that = this;
      let initData = {};
      initData.id = that.id
      this.showww=true
      initData.user_id = globaluserId();
   	  initData.setHouse_id =that.setHouse_id
      initData.roomHouse_id =that.roomId
      browseSetHouseItem(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {           
         let list=response.data.data.date
         console.log(response.data.data);
         for(let i=0;i<list.length;i++){
            if(list[i].roomHouse_id == that.roomId){
                that.arrList.push(list[i])
                that.name=list[i].roomName
            }else{
                that.houseList.push(list[i])
                that.houseName=list[i].roomName
            }
         }
		  })
      })


    }
	,//我的接口   
    setHouseItemPhone(e) {
      console.log(e)
      let that = this;
      var initData = {}
      initData.id = e.id
      initData.user_id = globaluserId();
      setHouseItemPhone(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          if(response.data.data.data[0]){
            that.path= response.data.data.data[0].path
          }
          if(!that.path){
            Toast('暂无实物图')
            that.showee= false
          }else{
            that.showee= true
          }
		  })
      });
    }
	,
    datetime() {},
    showPopup() {
      this.showaa = !this.showaa;
    //   console.log(this.showaa);
    },

    
    leftReturn() {
      this.$router.go(-1);
    },

    initData() {
      let that = this;
	  let initData={}
      initData.room_id = that.roomId;
      initData.user_id = globaluserId();
      roomDetail(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.appointmentData = response.data.data;
          that.appointmentData.photo =
            response.data.data.roomPhotoList.length > 0
              ? response.data.data.roomPhotoList[0].roomPhotoPath
              : that.errorImg;
          that.appointmentData.photo =
            response.data.data.roomPhotoList.length > 0
              ? response.data.data.roomPhotoList[0].roomPhotoPath
              : that.errorImg;
          that.offerPrice = response.data.data.roomRent; //报价
          that.floorPrice = response.data.data.floorPrice; //报价
        });
      });
      // console.log(this.show)
    },
    chooseTime() {
      this.show = !this.show;
    },
    save() {},
  },
};
</script>

<style scoped>
.diamod {
  width: 8px;
  height: 8px;
  background-color: #3b98ff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-right: 5px;
  display: inline-block;
}

.van-dialog img {
  width: 200px;
  height: 200px;
  padding-left: 65px;
}

/*保存按钮不可点击样式*/
.saveButton_Disable {
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll
    0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 240px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left: 10px;
  margin-right: 5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 10px;
  /*border-radius: 50%;*/
  /*background-color: #ff5d3b;*/
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.roomMesage {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
  padding-top: 10px;
  padding-left: 20px;
}
.areaDiv {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;
}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}
.right {
  display: flex;
  font-size: 10px;
  right: 30px;
  justify-content: right;
  padding-left: 200px;
  margin-top: -26px;
}
.rightSpan {
  font-size: 12px;
  color: rgba(86, 155, 236, 1);
}
.newtime {
  padding-left: 20px;
  font-size: 20px;
  color: #f00;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(
    to right,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}

.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.part-inputpart-row-header {
  font-size: 15px;
  font-weight: 900;
}
.part-inputpart-row-right {
  margin-right: 20px;
  font-size: 15px;
  font-weight: 900;
}
.part-inputpart-row-short-graytext {
  color: #d8d8d8;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;
}

.address {
  font-weight: bold;
  font-size: 12px;
  font-family: PingFangSC-Regular;
}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: rgba(34, 34, 34, 1);
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  padding-bottom: 10px;
  position: relative;
  width: 92%;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.selectTime {
  width: 10rem;
  height: 3rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}
.nopadding {
  padding: 0;
  flex: auto;
}
</style>
